<!-- 筛选侧边栏导航 -->
<template>
  <div class="box">
    <el-row class="tac">
      <el-col :span="8">
        <el-menu
            default-active="2"
            class="el-menu-vertical-demo"
            @open="handleOpen"
            @close="handleClose"
            background-color="#191C32"
            text-color="#fff"
            active-text-color="gray"
            :unique-opened="true"
        >
          <div class="title">{{$t('filtrate')}}</div>
          <el-submenu index="1">
            <template slot="title">
              <span>{{$t('singer')}}</span>
            </template>
            <el-menu-item-group>
              <el-checkbox-group
                  v-model="$store.state.searchSingerList"
                  :max="1"
                  style="display: flex; flex-flow: column"
                  @change="changeSinger"
                  v-for="(item,index) in singerList"
                  :key="index"
              >
                <div class="checkbox-item">
                  <p>{{ item }}</p>
                  <el-checkbox :label="item"></el-checkbox>
                </div>

              </el-checkbox-group>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="2">
            <template slot="title">
              <span>{{$t('Style')}}</span>
            </template>
            <el-menu-item-group>
              <el-checkbox-group
                  v-model="$store.state.searchStyleList"
                  style="display: flex; flex-flow: column"
                  @change="changeStyle"
                  v-for="(item,index) in styleList"
                  :key="index"
              >
                <div v-if="$route.fullPath != '/musicLibrary/bgm'" class="a" v-for="(rol,i) in item.child" :key="i">
                  <div class="checkbox-item">
                    <p v-if="lang == 'cn'">{{ rol.name }}</p>
					<p v-if="lang != 'cn'">{{ rol.ko_name }}</p>
                    <el-checkbox v-if="lang == 'cn'" :label="rol.name"></el-checkbox>
					<el-checkbox v-if="lang != 'cn'" :label="rol.ko_name"></el-checkbox>
                  </div>
                </div>
				<div v-if="$route.fullPath == '/musicLibrary/bgm'" class="a">
				  <div class="checkbox-item">
				   <!-- <p>{{ item.name }}</p>
				    <el-checkbox :label="item.name"></el-checkbox> -->
					<p v-if="lang == 'cn'">{{ item.name }}</p>
					<p v-if="lang != 'cn'">{{ item.ko_name }}</p>
					<el-checkbox v-if="lang == 'cn'" :label="item.name"></el-checkbox>
					<el-checkbox v-if="lang != 'cn'" :label="item.ko_name"></el-checkbox>
				  </div>
				</div>
              </el-checkbox-group>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <span>{{$t('instruments')}}</span>
            </template>
            <el-menu-item-group>
              <el-checkbox-group
                  v-model="$store.state.searchMusicalInstrumentsList"
                  :max="1"
                  style="display: flex; flex-flow: column"
                  @change="changeMusicalInstruments"
                  v-for="(item,index) in musicalList"
                  :key="index"
              >
                <div class="checkbox-item">
                  <!-- <p>{{ item.name }}</p>
                  <el-checkbox :label="item.name"></el-checkbox> -->
				  <p v-if="lang == 'cn'">{{ item.name }}</p>
				  <p v-if="lang != 'cn'">{{ item.ko_name }}</p>
				  <el-checkbox v-if="lang == 'cn'" :label="item.name"></el-checkbox>
				  <el-checkbox v-if="lang != 'cn'" :label="item.ko_name"></el-checkbox>
                </div>
              </el-checkbox-group>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">
              <span>{{$t('emotion')}}</span>
            </template>
            <el-menu-item-group>
              <el-checkbox-group
                  v-model="$store.state.searchMoodList"
                  :max="1"
                  style="display: flex; flex-flow: column"
                  @change="changeMood"
                  v-for="(item,index) in moodList"
                  :key="index"
              >
                <div class="checkbox-item">
                  <!-- <p>{{ item.name }}</p>
                  <el-checkbox :label="item.name"></el-checkbox> -->
				  <p v-if="lang == 'cn'">{{ item.name }}</p>
				  <p v-if="lang != 'cn'">{{ item.ko_name }}</p>
				  <el-checkbox v-if="lang == 'cn'" :label="item.name"></el-checkbox>
				  <el-checkbox v-if="lang != 'cn'" :label="item.ko_name"></el-checkbox>
                </div>
              </el-checkbox-group>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">
              <span>{{$t('speed')}}</span>
            </template>
            <el-menu-item-group>
              <el-checkbox-group
                  v-model="$store.state.searchSpeedList"
                  :max="1"
                  style="display: flex; flex-flow: column"
                  @change="changeSpeed"
                  v-for="(item,index) in speedList"
                  :key="index"
              >
                <div class="checkbox-item">
                  <p>{{ item.min }}-{{ item.max }}</p>
                  <el-checkbox :label="item.min+'-'+item.max"></el-checkbox>
                </div>

              </el-checkbox-group>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {tagList} from "@/api/Home";

export default {
  data() {
    return {
      test: [],
      singerList: [], //歌手
      styleList: [], //风格
      musicalList: [], //乐器
      moodList: [], //情绪
      speedList: [
        {
          min: 30,
          max: 80,
          name: "慢",//this.$t("recommend.slowly")
        },
        {
          min: 80,
          max: 120,
          name: "中",
        },
        {
          min: 120,
          max: 180,
          name: "快",
        },
      ], //速度
      MusicList: [],//总列表
	  lang: '',
    };
  },
  created() {
	  console.log(this.$store.state.searchSpeedList,'searchSpeedList')
    this.TagList(); //所有标签列表
	this.lang = localStorage.getItem("lang") ? localStorage.getItem("lang") : this.$i18n.locale
  },
  watch: {
  	"$route.fullPath": {
  		handler(newValue, oldValue) {
  			console.log("::::::::::::::", newValue);
			this.TagList()
			// if(newValue == '/musicLibrary/bgm'){
			// 	//风格
			// 	this.styleList = res.data.list.bgm_genre_arr
			// 	//情绪
			// 	this.moodList = res.data.list.bgm_mood_arr
			// }else {
			// 	this.styleList = res.data.list.genre_arr
			// 	this.moodList = res.data.list.mood_arr
			// }
  			// // this.params.title = this.$store.state.title
  			// this.params.title = this.$route.query.title ? this.$route.query.title : ''
  			// this.params.type = 1
  			// this.getMusicList(true);
  		},
  	},
  },
  methods: {
    // 所有标签列表
    TagList() {
      tagList({})
          .then((res) => {
			  // let arr = res.data.list
            this.$store.commit('changeTagList', res.data.list)
			if(this.$route.fullPath == '/musicLibrary/bgm'){
				//风格
				this.styleList = res.data.list.bgm_genre_arr
				//情绪
				this.moodList = res.data.list.bgm_mood_arr
			}else {
				this.styleList = res.data.list.genre_arr
				this.moodList = res.data.list.mood_arr
			}
            // //风格
            // this.styleList = res.data.list.genre_arr
            // console.log(res.data.list, "genre_arr标签列表成功");
            //歌手
            this.singerList = res.data.list.suit_arr
            //乐器
            this.musicalList = res.data.list.instrument_arr
            console.log(this.musicalList, " this.musicalList成功");
            // //情绪
            // this.moodList = res.data.list.mood_arr
            //速度
            // this.speedList= res.data.list.suit_arr
          })
          .catch((err) => {
            console.log(err, "所有标签列表失败");
          });
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    changeSinger(val) {
      console.log("Singer:", val);
      this.$store.commit("changeSingerValue", val);
    },
    changeStyle(val) {
      console.log("Style:", val);
      this.$store.commit("changeStyleValue", val);
    },
    changeMusicalInstruments(val) {
      console.log("MusicalInstruments:", val);
      this.$store.commit("changeMusicalInstrumentsValue", val);
    },
    changeMood(val) {
      console.log("Mood:", val);
      this.$store.commit("changeMoodValue", val);
    },
    changeSpeed(val) {
      console.log("changeSpeed:", val);
      this.$store.commit("changeSpeedValue", val);
    },
  },
};
</script>

<style scoped lang="less">
// body::-webkit-scrollbar {
//     display: none;
// }

.box {
  width: 100%;

  ::v-deep .el-radio__inner {
    border: 0.0625rem solid #dcdfe6;
    border-radius: 100%;
    width: 0.875rem;
    height: 0.875rem;
    background-color: transparent !important;
    cursor: pointer;
    box-sizing: border-box;
  }

  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: gray !important;
    background: gray !important;
  }

  ::v-deep .el-submenu__title {
    vertical-align: middle;
    color: @musicLibraryFilterMenuNavTextColor !important;
    background-color: @musicLibraryFilterMenuNavBGColor !important;
  }

  ::v-deep .el-menu-item-group > ul {
    padding: 0;
    max-height: 200px;
    overflow: auto;
  }


  ::v-deep .el-menu-item-group > ul > li {
    color: @musicLibraryFilterMenuNavTextColor !important;
    background-color: @musicLibraryFilterMenuNavBGColor !important;
  }


  ::v-deep .el-menu-item-group {
    color: @musicLibraryFilterMenuNavTextColor !important;
    background-color: @musicLibraryFilterMenuNavBGColor !important;
  }

  ::v-deep .el-menu-item-group > ul::-webkit-scrollbar {
    display: none !important;
  }

  .el-menu-vertical-demo {
    // width: 13.75rem !important;
	// width: 30% !important;
	width: 90% !important;
    //height: 31.1875rem !important;
    background: @musicLibraryFilterMenuNavBGColor !important;
    border-radius: 0.375rem;
    backdrop-filter: blur(3.125rem);
    border-right: solid 0.0625rem @musicLibraryFilterMenuNavBGColor !important;
    list-style: none;
    position: absolute !important;
    // top: 24px !important;
    // left: 10% !important;

    .title {
      width: 40px;
      height: 28px;
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: @musicLibraryFilterMenuNavTitleColor;
      line-height: 28px;
      margin-left: 24px;
      margin-top: 14px;
      margin-bottom: 14px;
    }

    .checkbox-item {
      display: flex;
      justify-content: space-between;
      padding: 10px 20px;

      p {
        width: 100px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
      }

      ::v-deep .el-checkbox__label {
        display: none !important;
      }

      ::v-deep .el-checkbox__input {
        border-radius: 50%;
      }

      ::v-deep .el-checkbox__inner {
        border-radius: 50%;
      }
    }
  }


}
</style>