<template>
	<div class="shells">
		<div class="filter-view">
			<filterMenu></filterMenu>
		</div>
		<div class="content">
			<div class="top-nav">
				<div class="nav-lf">
					<MusicType :width="100" :tabsList="tabsList" :dropdownList="dropdownList"></MusicType>
				</div>
			</div>
			<router-view></router-view>
		</div>
	</div>
</template>

<script>
	import filterMenu from "@/components/main/filterMenu.vue";
	import MusicType from "@/components/content/MusicType.vue";

	export default {
		props: {},
		data() {
			return {
				DemoseaList: [{
						coverimg: "https://fuhuiduoduo.axa2.com/upload/static/icon-touxiang@2x(1).png",
						name: "Ollie Nichols",
						time: "09:12",
						username: "Maud Hampton",
						userphoto: "https://fuhuiduoduo.axa2.com/upload/static/icon-touxiang@2x(1).png",
						tabslist: [{
								name: "男女合唱",
							},
							{
								name: "Hip Hop",
							},
							{
								name: "Experimental",
							},
							{
								name: "民族乐器",
							},
							{
								name: "寂寞",
							},
							{
								name: "120--280",
							},
						],
					},
					{
						coverimg: "https://fuhuiduoduo.axa2.com/upload/static/icon-touxiang@2x(1).png",
						name: "Ollie Nichols",
						time: "09:12",
						username: "Maud Hampton",
						userphoto: "https://fuhuiduoduo.axa2.com/upload/static/icon-touxiang@2x(1).png",
						tabslist: [{
								name: "男女合唱",
							},
							{
								name: "Hip Hop",
							},
							{
								name: "Experimental",
							},
							{
								name: "民族乐器",
							},
							{
								name: "寂寞",
							},
							{
								name: "120--280",
							},
						],
					},
				],
				num: 40,
				type: true, // 控制组件MusicType右侧 全部功能是否显示
				// tabs数据
				tabsList: [
					// {
					//   name: this.$t('finishedmusic'),
					//   url: "/musicLibrary/producthmusic",
					// },
					// {
					//   name: `DEMO(${this.$t('overseas')})`,
					//   url: "/musicLibrary/seademo",
					// },
					// {
					//   name: `DEMO(${this.$t('hinterland')})`,
					//   url: "/musicLibrary/inlanddemo",
					// },
					// {
					//   name: this.$t('recommend.WorkCollaboration'),
					//   url: "/musicLibrary/workcollaboration",
					// },
					// {
					//   name: "BGM",
					//   url: "/musicLibrary/bgm",
					// },
				],
				// 全部下来菜单
				dropdownList: [{
						name: "全部",
					},
					{
						name: "分类1",
					},
					{
						name: "分类2",
					},
				],
			};
		},
		components: {
			filterMenu,
			MusicType
		},
		computed: {},
		created() {},
		mounted() {
			if (this.$store.state.identity != 3) {
				if (this.$store.state.identity == 2) {
					let arr = [{
							name: this.$t('finishedmusic'),
							url: "/musicLibrary/producthmusic",
						},
						{
							name: `DEMO(${this.$t('overseas')})`,
							url: "/musicLibrary/seademo",
						},
						{
							name: `DEMO(${this.$t('hinterland')})`,
							url: "/musicLibrary/inlanddemo",
						},
						// {
						//   name: this.$t('recommend.WorkCollaboration'),
						//   url: "/musicLibrary/workcollaboration",
						// },
						{
							name: "BGM",
							url: "/musicLibrary/bgm",
						},
					]
					this.tabsList = arr
				} else {
					let arr = [{
							name: this.$t('finishedmusic'),
							url: "/musicLibrary/producthmusic",
						},
						{
							name: `DEMO(${this.$t('overseas')})`,
							url: "/musicLibrary/seademo",
						},
						{
							name: `DEMO(${this.$t('hinterland')})`,
							url: "/musicLibrary/inlanddemo",
						},
						// {
						//   name: this.$t('recommend.WorkCollaboration'),
						//   url: "/musicLibrary/workcollaboration",
						// },
						// {
						//   name: "BGM",
						//   url: "/musicLibrary/bgm",
						// },
					]
					this.tabsList = arr
				}


			} else if (this.$store.state.identity == 3) {
				let arr = [{
						name: this.$t('finishedmusic'),
						url: "/musicLibrary/producthmusic",
					},
					{
						name: `DEMO(${this.$t('overseas')})`,
						url: "/musicLibrary/seademo",
					},
					{
						name: `DEMO(${this.$t('hinterland')})`,
						url: "/musicLibrary/inlanddemo",
					},
					{
						name: this.$t('recommend.WorkCollaboration'),
						url: "/musicLibrary/workcollaboration",
					},
					// {
					//   name: "BGM",
					//   url: "/musicLibrary/bgm",
					// },
				]
				this.tabsList = arr
			}
		},
		watch: {},
		methods: {},
	};
</script>

<style scoped lang='less'>
	.shells {
		display: flex;
		justify-content: flex-start;

		.filter-view {
			width: 20%;
		}
	}

	.content {
		width: 80%;
		min-height: 600px;
		/* margin-top: 20px; */
	}

	.top-nav {
		//height: 50px;
		//display: flex;
		//justify-content: space-between;
	}

	.el-icon-arrow-down {
		margin-left: 10px;
	}

	.nav-rg {
		background: rgba(255, 255, 255, 0.08);
		border-radius: 6px;
		backdrop-filter: blur(50px);
		width: 80px;
		height: 50px;
		font-size: 16px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #ffffff;
		line-height: 22px;
		display: flex;
		align-items: center;
		margin-right: 10px;
	}
</style>
